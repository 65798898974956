import { TestDriveImage, WaitingApprovalImage } from 'assets'
import { useHistory } from 'react-router-dom'

function TestDriveResultPage() {
  const { location, replace } = useHistory()
  const dataRouter = location?.state?.data
  if (dataRouter === undefined) {
    window.location.replace('/')
  }
  const data = [
    { id: 1, label: 'Tempat', value: dataRouter?.workshop_name },
    { id: 2, label: 'Tanggal', value: dataRouter?.date },
    { id: 3, label: 'Jam', value: dataRouter?.hours }
  ]
  return (
    <div className="py-20 flex justify-center text-center">
      <div className="grid grid-cols-1 gap-6 bg-white border border-solid text-center rounded border-gray-200 p-16">
        {dataRouter?.status === 'WAITING_APPROVAL' ? (
          <>
            <img src={WaitingApprovalImage} className="h-[180.89px] w-[240px] mx-auto" alt="" />
            <div>
              <p className="display-sm-semibold text-gray-900 mb-3">
                Tunggu Approval Dari Perusahaan
              </p>
              <p className="text-xl-regular text-gray-700">
                {location?.pathname?.includes('jual')
                  ? 'inspeksi'
                  : location?.pathname?.includes('servis-mobil')
                  ? 'servis'
                  : 'test drive'}{' '}
                akan dijadwalkan setelah mendapatkan persetujuan oleh perusahaan pemilik mobil Anda.
              </p>
            </div>
          </>
        ) : (
          <>
            <img src={TestDriveImage} className="h-[180.89px] w-[240px] mx-auto" alt="" />
            <div>
              <p className="display-sm-semibold text-gray-900 mb-3">
                {location?.pathname?.includes('jual')
                  ? 'Inspeksi Telah Diajukan'
                  : location?.pathname?.includes('servis-mobil')
                  ? 'Servis Telah Dijadwalkan'
                  : 'Test Drive Telah Diajukan'}
              </p>
              <p className="text-xl-regular text-gray-700">
                Lakukan{' '}
                {location?.pathname?.includes('jual')
                  ? 'inspeksi'
                  : location?.pathname?.includes('servis-mobil')
                  ? 'servis'
                  : 'test drive'}{' '}
                pada tempat dan jadwal yang telah Anda tetapkan
              </p>
            </div>
            <div className="grid grid-cols-3 gap-6 text-center">
              {data.map((el) => (
                <div key={el.id}>
                  <p className="text-sm-regular text-gray-500">{el.label}</p>
                  <p className="text-md-medium text-gray-700">{el.value}</p>
                </div>
              ))}
            </div>
          </>
        )}
        <div
          onClick={() => replace('/')}
          className="text-md-medium text-primary-600 cursor-pointer">
          Kembali ke halaman utama
        </div>
      </div>
    </div>
  )
}

export default TestDriveResultPage
