import { onRupiah } from "utils/formatCurrency"

const ServiceInvoiceContent = ({ dataSpareparts, dataLayanans }) => {
  return (
    <table className='border-spacing-y-1'>
      <thead className="text-left text-xs-medium text-gray-900">
        <th className="w-[30%]">Item</th>
        <th className="w-[5%] text-right">QTY</th>
        <th className="pl-5">Harga</th>
        <th>Disc</th>
        <th className="text-right">Total</th>
      </thead>

      {/* sparepart list */}
      {
        dataSpareparts?.length > 0 && (
          <>
            <p className="text-xs-medium text-gray-900 mt-5">Sparepart</p>
            {
              dataSpareparts?.map((item, index) => (
                <tr className="text-left text-xs-regular text-gray-700 mb-5" key={index}>
                  <td className="w-[30%] align-top pb-2">{item.label}</td>
                  <td className="w-[5%] align-top">{item.quantity !== 0 ? `x${item.quantity}` : '-'}</td>
                  <td className="pl-5 align-top">Rp {onRupiah(item.price)}</td>
                  <td className='pb-2 align-top'>
                    {
                      item?.discounts?.length > 0 ? item.discounts.map((el, i) => (
                        <p key={i}>
                          {
                            el.price_total > 0 ?
                              `- Rp. ${onRupiah(el.price_total)} ${el.discount_type === 'PERCENT' ? `(${el?.discount}%)` : ''}` :
                              '-'
                          }
                        </p>
                      )) : '-'
                    }
                  </td>
                  <td className="text-right align-top">Rp {onRupiah(item.total)}</td>
                </tr>
              ))
            }
          </>
        )
      }

      {/* layanan list */}
      {
        dataLayanans?.length > 0 && (
          <>
            <p className="text-xs-medium text-gray-900 mt-5">Layanan</p>
            {
              dataLayanans?.map((item, index) => (
                <tr className="text-left text-xs-regular text-gray-700 mb-5" key={index}>
                  <td className="w-[30%] align-top pb-2">{item.label}</td>
                  <td className="w-[5%] align-top">{item.quantity !== 0 ? `x${item.quantity}` : '-'}</td>
                  <td className="pl-5 align-top">Rp {onRupiah(item.price)}</td>
                  <td className='pb-2 align-top'>
                    {
                      item?.discounts?.length > 0 ? item.discounts.map((el, i) => (
                        <p key={i}>
                          {
                            el.price_total > 0 ?
                              `- Rp. ${onRupiah(el.price_total)} ${el.discount_type === 'PERCENT' ? `(${el?.discount}%)` : ''}` :
                              '-'
                          }
                        </p>
                      )) : '-'
                    }
                  </td>
                  <td className="text-right align-top">Rp {onRupiah(item.total)}</td>
                </tr>
              ))
            }
          </>
        )
      }

    </table>
  )
}

export default ServiceInvoiceContent