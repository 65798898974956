import { Input } from 'antd'
import React from 'react'

function FormInput({ data }) {
  const { label, ...inputProps } = data
  return (
    <div className="grid grid-cols-1 gap-2">
      <p className="text-sm-medium text-gray-700">{label} {data?.mandatory && <span className="text-danger-500">*</span>}</p>
      <Input className="rounded-md" {...inputProps} />
    </div>
  )
}

export default FormInput
