import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Col, Row } from 'antd'
import {
  BeliContentTransaction,
  CardBarcode,
  CardHistoryTransaction,
  CardInvoice,
  InspeksiContentTransaction,
  InvoiceContent,
  JualContentTransaction,
  ServisContentTransaction
} from 'features/transaction'
import { useDetailTransaction } from 'features/transaction/hooks'
import TestDriveContentTransaction from 'features/transaction/components/content/test-drive-content-transaction'
import { useState, useRef, useEffect } from 'react'
import { useCreditCard, usePayment } from 'hooks'
import ModalSelectPaymentMethod from 'components/ui/modal/modal-select-payment-method'
import ModalAddCreditCard from 'components/ui/modal/modal-add-credit-card'
import ModalCreditCards from 'components/ui/modal/modal-credit-cards'
import { ModalServisConfirmationPayment } from 'features/servis/components/modal'
import { DownloadIcon } from 'assets'
import CustomButton from 'components/ui/custom-button'
import { ModalLayout } from 'layouts'
import { useReactToPrint } from 'react-to-print'

const serviceList = ['servis', 'test-drive', 'beli', 'jual', 'inspeksi']
function DetailServicePage() {
  const { location, replace, push, goBack } = useHistory()
  const { serviceName, idTransaction } = useParams()
  const componentRef = useRef()
  const [idTrx, setIdTrx] = useState('')
  const [modalConfirmationCheckout, setModalConfirmationCheckout] = useState(false)
  const [modalDownloadInvoice, setModalDownloadInvoice] = useState(false)
  if (
    !serviceList.includes(serviceName) &&
    (serviceName !== undefined || idTransaction !== undefined)
  ) {
    replace('/transaction')
  }

  const id = idTransaction ? idTransaction : location?.state?.id
  const serviceType = serviceName ? serviceName?.toLowerCase() : location?.state?.type
  if (id === undefined || serviceType === undefined) {
    replace('/transaction')
  }
  const userData = JSON.parse(localStorage.getItem('user'))
  const accountType = localStorage.getItem('account_type')
  const {
    modalSelectPayment,
    openModalPaymentMethod,
    closeModalPaymentMethod,
    selectedPayment,
    handleSelectedPayment,
    getPaymentObject,
    handleCreditCardToken
  } = usePayment()
  const {
    handleConfirmationPayment,
    handleApprovalRecommendationService,
    handleApprovalService,
    handleLakukanPembayaran,
    unitData,
    putApprovalService,
    recommendationServices,
    putRecommendationService,
    priceTotalRecommendation,
    grandTotalRecommendation,
    bookingProcessDate,
    isLoading,
    checkoutIsLoading
  } = useDetailTransaction(goBack, replace, serviceType, id, getPaymentObject)
  const {
    creditCards,
    newCreditCard,
    selectedCreditCard,
    modalCardList,
    modalAddCard,
    isAddCardLoading,
    errorsAddCard,
    handleChangeCreditCard,
    submitAddCard,
    openModalCardList,
    closeModalCardList,
    openModalAddCard,
    closeModalAddCard,
    handleSelectedCreditCard
  } = useCreditCard(true)
  const navigateHandler = (path, id) => {
    if (id !== undefined || id !== null) {
      push({ pathname: path, state: { id: id } })
    } else {
      push(path)
    }
  }
  function openModalConfirmationCheckout() {
    setModalConfirmationCheckout(true)
  }
  function closeModalConfirmationCheckout() {
    setModalConfirmationCheckout(false)
  }
  const renderServiceMap = {
    jual: (
      <JualContentTransaction
        data={unitData}
        status={unitData?.transaction_status?.toLowerCase()}
        navigateHandler={navigateHandler}
        serviceName="jual"
      />
    ),
    beli: (
      <BeliContentTransaction
        validationCheckPaymentMethod={validationCheckPaymentMethod}
        status={unitData?.transaction?.status?.toLowerCase()}
        handleLakukanPembayaran={handleLakukanPembayaran}
        navigateHandler={navigateHandler}
        serviceName={'beli'}
        data={unitData}
        bookingProcessDate={unitData?.booking}
      />
    ),
    inspeksi: (
      <InspeksiContentTransaction
        validationCheckPaymentMethod={validationCheckPaymentMethod}
        handleLakukanPembayaran={handleLakukanPembayaran}
        status={unitData?.inspection_status?.toLowerCase()}
        serviceName={'inspeksi'}
        userId={userData?.id}
        navigateHandler={navigateHandler}
        data={unitData}
      />
    ),
    'test drive': (
      <TestDriveContentTransaction
        data={unitData}
        status={unitData?.transaction?.status?.toLowerCase()}
        navigateHandler={navigateHandler}
        serviceName="test drive"
      />
    ),
    servis: (
      <ServisContentTransaction
        isLoading={isLoading}
        validationCheckPaymentMethod={validationCheckPaymentMethod}
        status={unitData?.booking_invoice?.transaction_detail?.status?.toLowerCase()}
        data={unitData}
        accountType={accountType}
        putApprovalService={putApprovalService}
        recommendationServices={recommendationServices}
        putRecommendationService={putRecommendationService}
        priceTotalRecommendation={priceTotalRecommendation}
        grandTotalRecommendation={grandTotalRecommendation}
        handleApprovalRecommendationService={handleApprovalRecommendationService}
        handleApprovalService={handleApprovalService}
        userData={userData}
        navigateHandler={navigateHandler}
        serviceName="servis"
      />
    )
  }

  useEffect(() => {
    if (unitData) {
      if (serviceType === 'jual' || serviceType === 'beli' || serviceType === 'test drive') {
        setIdTrx(unitData?.transaction?.id)
      } else if (serviceType === 'servis') {
        setIdTrx(unitData?.booking_invoice?.transaction_id)
      } else if (serviceType === 'inspeksi') {
        setIdTrx(unitData?.transaction_id)
      }
    }
  }, [unitData])
  function validationCheckPaymentMethod() {
    if (serviceType) {
      if (
        unitData?.transaction?.payment_method == '' ||
        unitData?.payment_method == '' ||
        unitData?.booking_invoice?.transaction_detail?.payment_method == '' ||
        unitData?.booking_invoice?.transaction_detail?.payment_status == 'expired'
      ) {
        return openModalPaymentMethod()
      } else {
        return handleLakukanPembayaran()
      }
    }
    // if (
    //   unitData?.booking_invoice?.transaction_detail?.payment_status === 'expired' ||
    //   unitData?.booking_invoice?.transaction_detail?.payment_method === ''
    // ) {
    //   openModalPaymentMethod()
    // } else if (unitData?.booking_invoice?.transaction_detail?.payment_status === 'pending') {
    //   handleLakukanPembayaran()
    // }
  }
  function handleSelectedCard(card) {
    handleCreditCardToken(card.id)
    handleSelectedCreditCard(card)
    closeModalCardList()
    openModalConfirmationCheckout()
  }
  function handlePayment(id) {
    handleSelectedPayment(id)
    closeModalPaymentMethod()
    if (id === 'card') {
      openModalCardList()
    } else {
      openModalConfirmationCheckout()
    }
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: `${window.location.pathname.split('/')[1]}-${
    //   window.location.pathname.split('/')[2]
    // }-payment-invoice`
    documentTitle: unitData?.invoiceData?.invoice_no
  })

  return (
    <Row className="gap-6">
      {modalCardList ? (
        <ModalCreditCards
          selectedCreditCard={selectedCreditCard}
          openModal={modalCardList}
          onCancel={closeModalCardList}
          creditCards={creditCards}
          openModalAddCard={openModalAddCard}
          handleSelectedCard={handleSelectedCard}
        />
      ) : null}
      {modalAddCard ? (
        <ModalAddCreditCard
          errors={errorsAddCard}
          creditCard={newCreditCard}
          openModal={modalAddCard}
          submitAddCard={submitAddCard}
          isAddCardLoading={isAddCardLoading}
          onCancel={closeModalAddCard}
          handleChange={handleChangeCreditCard}
        />
      ) : null}
      {modalSelectPayment ? (
        <ModalSelectPaymentMethod
          modalOpen={modalSelectPayment}
          closeModal={closeModalPaymentMethod}
          handlePayment={handlePayment}
        />
      ) : null}
      {modalConfirmationCheckout ? (
        <ModalServisConfirmationPayment
          handleConfirmationPayment={handleConfirmationPayment}
          closeConfirmationCheckoutModal={closeModalConfirmationCheckout}
          confirmationCheckoutModal={modalConfirmationCheckout}
          dataCar={unitData}
          selectedPayment={selectedPayment}
          selectedCreditCard={selectedCreditCard}
          checkoutIsLoading={checkoutIsLoading}
          serviceType={serviceType}
        />
      ) : null}
      {modalDownloadInvoice ? (
        <ModalLayout
          openModal={modalDownloadInvoice}
          className={'w-full max-w-2xl'}
          headerTitle={
            <CustomButton
              type="plain"
              onClick={handlePrint}
              label={
                <div className="flex items-center gap-3 justify-center stroke-gray-700">
                  <DownloadIcon className="h-5 w-5" /> Download Invoice
                </div>
              }
            />
          }
          onCancel={() => setModalDownloadInvoice(false)}>
          <InvoiceContent key="invoice-single-content-print" data={unitData} ref={componentRef} />
        </ModalLayout>
      ) : null}
      {/* Header */}
      <Col
        span={24}
        className="flex gap-1 items-center text-sm-medium md:text-xl-medium text-gray-900 py-2">
        <NavLink
          to={'/transaction'}
          className={'hover:text-primary-500 text-gray-900 no-underline'}>
          Transaksi
        </NavLink>{' '}
        / <span className="text-primary-500">Detail Transaksi</span>
      </Col>
      {/* Content */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        <Row>
          {/* left content */}
          <Col span={24} md={16} className="pb-3 md:pr-3 ">
            <div className="flex flex-col space-y-5">{renderServiceMap[serviceType]}</div>
          </Col>
          {/* right content */}
          <Col span={24} md={8}>
            <div className="flex flex-col gap-5">
              {idTrx && serviceType !== 'test drive' && (
                <CardBarcode idTransaction={idTrx} serviceName={serviceType.toLowerCase()} />
              )}
              {serviceType !== 'test drive' &&
                serviceType !== 'jual' &&
                unitData?.catalog_invoice?.invoice_number &&
                (serviceType !== 'servis' ||
                  (serviceType === 'servis' &&
                    unitData?.booking_invoice?.transaction_detail?.status?.toLowerCase() ===
                      'done')) && (
                  <CardInvoice
                    data={unitData}
                    openModal={() => setModalDownloadInvoice(true)}
                    status={
                      unitData?.inspection_status?.toLowerCase() ||
                      unitData?.transaction?.status?.toLowerCase() ||
                      unitData?.booking_invoice?.transaction_detail?.status?.toLowerCase()
                    }
                  />
                )}
              <div className="order-first md:order-last">
                <CardHistoryTransaction data={unitData} />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DetailServicePage
