import './App.css'
import { BrowserRouter, Switch } from 'react-router-dom'
import { CustomRoute, routeList } from 'routes'
import * as Sentry from '@sentry/react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/id'
const twentyFourHoursInMs = 1000 * 60 * 60 * 24
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: 'always',
      refetchOnMount: 'always',
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs
    }
  }
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ToastContainer />
        <Switch>
          {routeList.map((route, idx) => (
            <CustomRoute key={idx} path={route.path} layout={route.layout} page={route.component} />
          ))}
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default Sentry.withProfiler(App)
