import React from 'react'

function FormRadio({ data }) {
  const { label, name, options } = data
  return (
    <div className="text-sm-medium text-gray-900 flex flex-col gap-3 md:gap-0 md:grid md:grid-cols-[200px_1fr] md:items-center">
      <label htmlFor={name}>{label} {data?.mandatory && <span className='text-danger-500'>*</span>}</label>
      <div className="flex gap-5 lg:gap-10 items-center">
        {options.map((opt) => (
          <div key={opt.id} className="flex gap-2">
            <input
              id={name}
              onChange={data.onChange}
              name={opt.name}
              value={opt.value}
              type={opt.type}
              checked={opt.checked}
            />
            <label htmlFor={opt.name}>{opt.label}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FormRadio
