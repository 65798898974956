import { Col, DatePicker, Row, Select, Typography } from 'antd'
import BadgeItem from 'components/ui/badge-item'
import CustomTable from 'components/ui/custom-table'
import { months } from 'data/constants'
import { useQuery } from 'react-query'
import { getReportList, getUsersReport } from '../service'
import { useState, useCallback } from 'react'
import { debounce } from 'lodash'
import { onRupiah } from 'utils/formatCurrency'
import moment from 'moment'
import { Link } from 'react-router-dom'

const monthFormat = 'MMM YYYY'
const initialFilter = {
  offset: 0,
  limit: 5,
  month: '',
  year: '',
  user_id: '',
  payment_status: ''
}

const optionStatus = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' }
]

function ReportTypeBills() {
  const token = localStorage.getItem('token')
  const [reportData, setReportData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [listUser, setListUser] = useState([])
  const [filter, setFilter] = useState({ ...initialFilter })

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    []
  )

  const handleChangeSelectedFilter = (name, val) => {
    setFilter((prev) => ({
      ...prev,
      offset: 0,
      [name]: val
    }))
    return debouncedChangeHandler()
  }

  const handleChangeTanggal = (val) => {
    if (val !== null) {
      const selectedMonth = moment(val).format('MM')
      const selectedYear = moment(val).format('YYYY')
      setFilter((prev) => ({
        ...prev,
        month: selectedMonth,
        year: selectedYear,
        offset: 0
      }))
    } else {
      setFilter((prev) => ({
        ...prev,
        month: '',
        year: '',
        offset: 0
      }))
    }
    return debouncedChangeHandler()
  }
  const handleChangeFilterIncludeOffset = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
      offset: 0
    }))
    return debouncedChangeHandler()
  }
  const onChangeNextPage = () => {
    if (currentPage !== Math.ceil(reportData?.meta?.total / filter.limit)) {
      handleChangeSelectedFilter('offset', currentPage * filter.limit)
      setCurrentPage((prev) => prev + 1)
    }
  }
  const onChangePrevPage = () => {
    if (currentPage > 1) {
      handleChangeSelectedFilter('offset', filter.offset - filter.limit)
      setCurrentPage((prev) => prev - 1)
    }
  }

  const { refetch } = useQuery(['getReportList', debouncedChangeHandler], {
    queryFn: () => {
      const dataSend = {
        token: token,
        params: filter
      }

      return getReportList(dataSend)
    },
    onSuccess: (data) => {
      setReportData(data)
    },
    enabled: Boolean(debouncedChangeHandler)
  })
  useQuery('getListUser', getUsersReport, {
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setListUser(newData)
    }
  })

  const columns = [
    {
      name: 'Tanggal',
      cell: (row) => moment(row.created_at).format('DD MMM YYYY'),
      sortable: true
    },
    {
      name: 'Nama Unit',
      selector: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <Typography.Text
            className="text-sm-medium"
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>
          <p className="text-xs-regular mt-1">{row.unit_police_number}</p>
        </div>
      ),
      sortable: true,
      width: '30%'
    },
    {
      name: 'User',
      selector: (row) => (
        <Typography.Text
          className="text-sm-medium"
          ellipsis={{
            tooltip: row.user_fullname
          }}>
          {row.user_fullname}
        </Typography.Text>
      ),
      sortable: true
    },
    {
      name: 'Layanan',
      selector: (row) => row.transaction_type,
      sortable: true,
      width: '13%'
    },
    {
      name: 'Status',
      selector: (row) => row.payment_status,
      cell: (row) => (
        <BadgeItem type={row.payment_status === 'Unpaid' ? 'primary' : 'success'}>
          {row.payment_status}
        </BadgeItem>
      ),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Biaya',
      selector: (row) => `Rp. ${onRupiah(row.transaction_profit)}`,
      sortable: true
    }
    // {
    // 	name: 'Action',
    // 	selector: row => row.id,
    // 	cell: (row) => <>

    // 	</>,
    // 	sortable: true,
    // },
  ]

  return (
    <Row className="gap-6">
      {/* Header */}
      <Col span={24} className="text-xl-medium text-gray-900">
        <Link className=" stroke-primary-600 text-gray-700 hover:text-primary-500" to={'/report'}>
          Report
        </Link>{' '}
        / <span className="text-primary-500">{`Total Tagihan`}</span>
      </Col>
      {/* Content */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* filter setting */}
        <Col span={24}>
          <div className="flex gap-2">
            {/* <Select
              placeholder="Bulan"
              options={months}
              onChange={(val) => handleChangeSelectedFilter('month', val)}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
            />
            <DatePicker
              placeholder="Tahun"
              onChange={(_, dateString) => handleChangeSelectedFilter('year', dateString)}
              picker="year"
              allowClear
            /> */}
            <DatePicker
              placeholder="Pilih Bulan & Tahun"
              className="rounded-md"
              allowClear
              format={monthFormat}
              picker="month"
              onChange={(val) => handleChangeTanggal(val)}
            />
            <Select
              placeholder="User"
              options={listUser}
              onChange={(val) => handleChangeSelectedFilter('user_id', val)}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              allowClear
            />
            <Select
              placeholder="Status"
              options={optionStatus}
              onChange={(val) => handleChangeSelectedFilter('payment_status', val)}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              allowClear
            />
          </div>
        </Col>
        {/* table content */}
        <div className="md:bg-white md:border border-solid border-gray-200 md:p-6 rounded-[4px]">
          <p className="display-xs-medium text-gray-900 mb-6">{`Daftar Tagihan`}</p>
          <CustomTable
            columns={columns}
            data={reportData?.data || []}
            currentPage={filter?.offset === 0 ? 1 : filter?.offset + 1}
            totalPerPage={
              currentPage * filter?.limit >= reportData?.meta.total
                ? reportData?.meta.total
                : currentPage * filter?.limit
            }
            lengthAllData={reportData?.meta?.total}
            defaultPageSize={filter?.limit}
            onChangePageSize={handleChangeFilterIncludeOffset}
            onChangeNextPage={onChangeNextPage}
            onChangePrevPage={onChangePrevPage}
          />
        </div>
      </Col>
    </Row>
  )
}

export default ReportTypeBills
