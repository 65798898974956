import axios from 'axios'
import { showErrorMessage } from 'utils/toastMessage'

const API = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
API.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    const { status } = error.response
    if (status === 401) {
      if (error.response.data.errors.length > 0) {
        if (error?.response.data?.errors[0]?.message !== 'Email and Password mismatch' && error?.response.data?.errors[0]?.message !== 'otp code invalid') {
          localStorage.clear()
          window.location.replace('/login')
          showErrorMessage('Waktu login Anda sudah habis')
        }
      } else {
        localStorage.clear()
        window.location.replace('/login')
      }
    }
    return Promise.reject(error)
  }
)
export default API
