import React from 'react'
import { Input } from 'antd'

const { TextArea } = Input
function FormTextArea({ data }) {
  const { label, ...inputProps } = data
  return (
    <div className="grid grid-cols-1 gap-2">
      <p className="text-sm-medium text-gray-700">{label} {data?.mandatory && <span className='text-danger-500'>*</span>}</p>
      <TextArea {...inputProps} />
    </div>
  )
}

export default FormTextArea
