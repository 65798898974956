import API from 'services'

const getDataTransaction = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/${args.account_type}/transactions`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      },
      params: args.params
    }
  )
  return response
}
const getRangeBookingProcess = async (catalogId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/catalogs/${catalogId}/booking-date`
  )
  return response.data
}
const getTransactionTypes = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/${args.account_type}/transactions/types`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
      // params: args.params
    }
  )
  return response.data
}
const getTransactionStatuses = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/${args.account_type}/transactions/statuses`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      },
      params: args.params
    }
  )
  return response.data
}
const getTransactionDetail = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/${args.url}`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    }
    // params: args.params
  })
  return response.data
}
const getRecommendtaionServicePrice = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/bookings/${args.id}/recommendation-service/calculation`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
      // params: args.params
    }
  )
  return response.data
}
const putApprovalServiceB2B = async (transactionId, boolean) => {
  const { data: response } = await API.put(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/bookings/${transactionId}/approval`,
    {
      is_approved: boolean
    }
  )
  return response.data
}
const putApprovalRecommendationService = async (transactionId, boolean) => {
  const { data: response } = await API.put(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/bookings/${transactionId}/approval-recommendation-service`,
    {
      is_approved: boolean
    }
  )
  return response.data
}

const postServisCheckout = async (bookingId, dataSend) => {
  {
    // booking_invoice.id
    const { data: response } = await API.put(
      `${process.env.REACT_APP_API_BASE_URL}/servis-service/admin/booking-invoices/${bookingId}/checkout`,
      {
        payment: dataSend
      }
    )
    return response.data
  }
}

const postInspeksiReCreatePayment = async (transactionId, dataSend) => {
  {
    // booking_invoice.id
    const { data: response } = await API.post(
      `${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit/${transactionId}/recreate-payment`,
      {
        payment: dataSend
      }
    )
    return response.data
  }
}

const postBeliReCreatePayment = async (transactionId, dataSend) => {
  {
    // booking_invoice.id
    const { data: response } = await API.post(
      `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/bookings/${transactionId}/recreate-payment`,
      {
        payment: dataSend
      }
    )
    return response.data
  }
}

const cancelPayment = async (transactionId) => {
  const { data: response } = await API.patch(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/transactions/${transactionId}/cancel-payment`
  )
  return response.data
}
export {
  cancelPayment,
  getDataTransaction,
  putApprovalServiceB2B,
  putApprovalRecommendationService,
  getRecommendtaionServicePrice,
  getTransactionTypes,
  getTransactionStatuses,
  getTransactionDetail,
  getRangeBookingProcess,
  postServisCheckout,
  postInspeksiReCreatePayment,
  postBeliReCreatePayment
}
