import useValidation from 'lib/useValidation'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getCities, getProvinces, getSubdistricts, getUnitList } from '../service'
import { getDetailUnitDashboard } from 'features/dashboard/service'
import { useHistory } from 'react-router-dom'
import { nameToString } from 'utils/nameToString'
import { useCallback } from 'react'
import { debounce } from 'lodash'
const initialDataCar = {
  id: '',
  name: '',
  jenis: 'Mobil Pribadi'
}

const initialError = {
  nama: false,
  phone: false,
  email: false,
  province: false,
  city: false,
  subdistrict: false,
  postal_code: false,
  alamat: false
}
const useDataService = (currentContent) => {
  const { location } = useHistory()
  const user = JSON.parse(localStorage.getItem('user'))
  const [dataUser, setDataUser] = useState(
    location?.state?.owner === undefined
      ? {
          nama: user?.name || user?.fullname || '',
          phone: user?.phone_number || '',
          email: user?.email || '',
          alamat: user?.address || '',
          province: {
            label: user?.province_name || null,
            value: user?.province_id || null
          },
          city: {
            label: user?.city_name || null,
            value: user?.city_id || null
          },
          subdistrict: {
            label: user?.sub_district_name || null,
            value: user?.sub_district_id || null
          },
          postal_code: user?.postal_code || '',
          workshop: { id: null }
        }
      : {
          ...location?.state?.owner,
          province: {
            label: user?.province_name || null,
            value: user?.province_id || null
          },
          city: {
            label: user?.city_name || null,
            value: user?.city_id || null
          },
          subdistrict: {
            label: user?.sub_district_name || null,
            value: user?.sub_district_id || null
          },
          postal_code: user?.postal_code || '',
          workshop: { id: null }
        }
  )
  const [units, setUnits] = useState([])
  const [keywords, setKeywords] = useState('')
  const { nameValidation, phonenumberValidation, emailValidation, addressValidation } =
    useValidation()
  const [selectedCar, setSelectedCar] = useState(
    location?.state?.unit === undefined ? { ...initialDataCar } : { ...location?.state?.unit }
  )
  const [detailSelectedCar, setDetailSelectedCar] = useState(null)
  const [errors, setErrors] = useState({ ...initialError })
  const [selectedTypeId, setSelectedTypeId] = useState(0)
  const [provincesList, setProvinces] = useState([])
  const [citiesList, setCities] = useState([])
  const [subdistrictsList, setSubdistricts] = useState([])
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    []
  )
  const handleChangeKeyword = (value) => {
    setKeywords(value)

    return debouncedChangeHandler()
  }

  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: Infinity,
    enabled: currentContent == 0
  })
  useQuery({
    queryKey: ['getCities', dataUser?.province?.value],
    queryFn: () => getCities(dataUser?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    enabled: dataUser?.province?.value !== undefined && currentContent == 0,
    staleTime: 0
  })
  useQuery({
    queryKey: ['getSubdistricts', dataUser?.city?.value],
    queryFn: () => getSubdistricts(dataUser?.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts(newData)
    },
    enabled: dataUser?.city?.value !== undefined && currentContent == 0,
    staleTime: 0
  })

  const { refetch } = useQuery({
    queryKey: 'getUnits',
    queryFn: () => getUnitList(keywords),
    onSuccess: (data) => {
      const newData = data?.filter((car) =>
        (car?.company_admin_id !== user?.id && car?.company_id !== 0) ||
        car?.company_admin_id === user?.id
          ? !car?.is_booked && !car?.is_in_catalog && !car?.is_in_service && !car?.is_inspected
          : !car?.is_booked &&
            !car?.is_in_catalog &&
            !car?.is_in_service &&
            !car?.is_inspected &&
            car?.is_service_paid
      )
      setUnits(newData)
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: Boolean(debouncedChangeHandler)
  })
  useQuery({
    queryKey: ['getUnitById', selectedCar?.id],
    queryFn: () => getDetailUnitDashboard(selectedCar?.id),
    onSuccess: (data) => {
      setDetailSelectedCar(data)
      setSelectedTypeId(data?.type_id)
    },
    enabled: selectedCar?.id !== undefined,
    staleTime: 0,
    refetchOnWindowFocus: false
  })
  const handleValidation = (name, value) => {
    let err = ''

    switch (name) {
      case 'nama':
        err = nameValidation(value)
        break
      case 'phone':
        err = phonenumberValidation(value)
        break
      case 'email':
        err = emailValidation(value)
        break
      case 'alamat':
        err = addressValidation(value)
        break
      default:
        err = ''
    }
    return err
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    let err = false
    err = handleValidation(name, value)
    setDataUser((prev) => ({
      ...prev,
      [name]: value
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: err
    }))
  }
  const handleSelectedCar = (id) => {
    const found = units.find((el) => el.id === id)
    setSelectedCar(found)
  }

  const handleChangeLocationDataService = (name, value) => {
    let newValue = {}
    if (name === 'province') {
      setCities([])
      setSubdistricts([])
      newValue = {
        ...dataUser,
        province: value,
        city: {},
        subdistrict: {}
      }
    } else if (name === 'city') {
      setSubdistricts([])
      newValue = {
        ...dataUser,
        city: value,
        subdistrict: {}
      }
    } else {
      newValue = {
        ...dataUser,
        subdistrict: value
      }
    }
    setDataUser(newValue)
  }

  function handleValidationDataService() {
    if (selectedCar.id === '') {
      return { status: false, errorMessage: 'Mohon pilih mobil terlebih dahulu' }
    }

    for (const [key] of Object.entries(errors)) {
      if (
        dataUser[key] == '' ||
        (typeof dataUser[key] === 'object' && dataUser[key].value == null)
      ) {
        return { status: false, errorMessage: `Mohon isi ${nameToString[key]}` }
      }
    }
    return { status: true, errorMessage: '' }
  }
  return {
    dataUser,
    units,
    keywords,
    handleChangeKeyword,
    detailSelectedCar,
    errors,
    selectedCar,
    setDataUser,
    handleSelectedCar,
    handleChange,
    selectedTypeId,
    handleValidationDataService,
    citiesList,
    subdistrictsList,
    provincesList,
    handleChangeLocationDataService
  }
}

export default useDataService
