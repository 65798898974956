import { Divider, Spin } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'

function CardRecommendationService({
  data,
  grandTotal,
  total,
  serviceType,
  handleApprovalRecommendationService,
  putRecommendationService
}) {
  return (
    <CardSectionHeader headerTitle="Rekomendasi Servis" className='order-2 md:order-none'>
      <div className="text-xs-regular text-gray-600 grid grid-cols-1 gap-3 pb-4">
        {/* {data?.length > 0 &&
          data?.map((item) => (
            <div key={item?.id} >
              <div className={'flex items-center justify-between'}>
                <p className="text-lg-medium text-gray-900">{item?.general_service_name}</p>
                <p className={`text-md-regular text-gray-700 ${item?.highlight ? 'text-primary-400' : ''}`}>
                  Rp. {
                    item?.highlight ? `(${onRupiah(item?.price_total)})` : onRupiah(item?.price_total)
                  }
                </p>
              </div>
              {
                item.sparepart_name !== '' ? (
                  <div className="flex justify-between text-md-regular text-gray-700">
                    <p>{item?.sparepart_name || ''}</p>
                  </div>
                ) : ''
              }
            </div>
          ))} */}

        <div className='overflow-x-scroll md:overflow-auto'>
          <table className='border-spacing-2 w-full whitespace-nowrap border-separate md:whitespace-normal'>
            <thead className="text-left text-md-medium text-gray-900">
              <th className="w-[25%]">Item</th>
              <th className="w-[5%] text-right">QTY</th>
              <th>Harga</th>
              <th>Disc</th>
              <th className="text-right">Total</th>
            </thead>

            {/* sparepart list */}
            {
              data?.dataSpareparts?.length > 0 && (
                <>
                  <p className="text-md-medium text-gray-900 mt-5">Sparepart</p>
                  {
                    data?.dataSpareparts?.map((item, index) => (
                      <tr className="text-left text-md-regular text-gray-700 mb-5" key={index}>
                        <td className="w-[30%] align-top pb-2">{item.label}</td>
                        <td className="w-[5%] align-top">{item.quantity !== 0 ? `x${item.quantity}` : '-'}</td>
                        <td className="align-top">Rp {onRupiah(item.price)}</td>
                        <td className='pb-2 align-top'>
                          {
                            item?.discounts?.length > 0 ? item.discounts.map((el, i) => (
                              <p key={i}>
                                {
                                  el.price_total > 0 ?
                                    `- Rp. ${onRupiah(el.price_total)} ${el.discount_type === 'PERCENT' ? `(${el?.discount}%)` : ''}` :
                                    '-'
                                }
                              </p>
                            )) : '-'
                          }
                        </td>
                        <td className="text-right align-top">Rp {onRupiah(item.total)}</td>
                      </tr>
                    ))
                  }
                </>
              )
            }

            {/* layanan list */}
            {
              data?.dataLayanans?.length > 0 && (
                <>
                  <p className="text-md-medium text-gray-900 mt-5">Layanan</p>
                  {
                    data?.dataLayanans?.map((item, index) => (
                      <tr className="text-left text-md-regular text-gray-700 mb-5" key={index}>
                        <td className="w-[30%] align-top pb-2">{item.label}</td>
                        <td className="w-[5%] align-top">{item.quantity !== 0 ? `x${item.quantity}` : '-'}</td>
                        <td className=" align-top">Rp {onRupiah(item.price)}</td>
                        <td className='pb-2 align-top'>
                          {
                            item?.discounts?.length > 0 ? item.discounts.map((el, i) => (
                              <p key={i}>
                                {
                                  el.price_total > 0 ?
                                    `- Rp. ${onRupiah(el.price_total)} ${el.discount_type === 'PERCENT' ? `(${el?.discount}%)` : ''}` :
                                    '-'
                                }
                              </p>
                            )) : '-'
                          }
                        </td>
                        <td className="text-right align-top">Rp {onRupiah(item.total)}</td>
                      </tr>
                    ))
                  }
                </>
              )
            }

          </table>
        </div>
        <div className="flex flex-col justify-end items-end py-4">
          <Divider className="m-0 my-5" />
          <div className="flex items-center gap-2 text-md-semibold">
            <span className="text-md-regular text-gray-700">Total :</span>
            <span className="text-lg-medium text-gray-900">Rp {onRupiah(total)}</span>
          </div>
          <Divider className="m-0 my-5" />
          <div className="flex items-center gap-2 text-md-semibold">
            <span className="text-md-regular text-gray-700">Grand Total :</span>
            <span className="text-lg-medium text-gray-900">Rp {onRupiah(grandTotal)}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Spin spinning={putRecommendationService?.isLoading}>
          <CustomButton
            onClick={() => handleApprovalRecommendationService(false)}
            type="plain"
            label={'Abaikan'}
          />
        </Spin>
        <Spin spinning={putRecommendationService?.isLoading}>
          <CustomButton
            onClick={() => handleApprovalRecommendationService(true)}
            label={'Setujui'}
          />
        </Spin>
      </div>
    </CardSectionHeader>
  )
}

export default CardRecommendationService
