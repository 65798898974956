import React from 'react'
import { Divider } from 'antd'
import { BirdMobilLogo, FacebookIcon, InstagramIcon } from 'assets'
import { onRupiah } from 'utils/formatCurrency'
import { DefaultInvoiceContent, ServiceInvoiceContent } from './invoice-content-types'

const InvoiceContent = React.forwardRef(({ data }, ref) => {
  const { invoiceData } = data
  const invoiceContentMap = {
    'Servis': (
      <ServiceInvoiceContent
        dataSpareparts={invoiceData?.invoice_sparepart_items}
        dataLayanans={invoiceData?.invoice_layanan_items}
      />
    ),
    'Beli': (
      <DefaultInvoiceContent
        invoiceData={invoiceData}
      />
    ),
    'Booking': (
      <DefaultInvoiceContent
        invoiceData={invoiceData}
      />
    ),
    'Inspeksi': (
      <DefaultInvoiceContent
        invoiceData={invoiceData}
      />
    ),

  }
  return (
    <div ref={ref} className='p-5 grid grid-cols-1 gap-5'>
      <div className='flex justify-between items-center'>
        <img src={BirdMobilLogo} className="w-full max-w-[100px] object-contain" />
        <p className='text-xs-regular text-gray-500'>{invoiceData?.invoice_date}</p>
      </div>
      <Divider className="m-0" />
      <div className='mb-5'>
        <div className='flex justify-between text-xs-medium'>
          <p className='text-gray-500'>Invoice No.</p>
          <p className='text-gray-700'>{invoiceData?.invoice_no}</p>
        </div>
        <div className='flex justify-between text-xs-medium'>
          <p className='text-gray-500'>Account</p>
          <p className='text-gray-700'>{invoiceData?.invoice_account}</p>
        </div>
        <div className='flex justify-between text-xs-medium'>
          <p className='text-gray-500'>Unit</p>
          <p className='text-gray-700'>{invoiceData?.invoice_unit_name}</p>
        </div>
        <div className='flex justify-between text-xs-medium'>
          <p className='text-gray-500'>Layanan</p>
          <p className='text-gray-700'>{invoiceData?.invoice_service_type}</p>
        </div>
      </div>
      {invoiceContentMap[invoiceData.invoice_service_type]}
      <div className='text-gray-700 grid grid-cols-1 text-xs-semibold gap-3 my-5'>
        <div className='flex justify-between'>
          <p>Subtotal :</p>
          <p>Rp. {onRupiah(invoiceData?.invoice_subtotal)}</p>
        </div>
        {
          invoiceData?.invoice_discount !== null && (
            <div className='flex justify-between'>
              <p>Discount :</p>
              <p>- Rp. {onRupiah(invoiceData?.invoice_discount)}</p>
            </div>
          )
        }
        {
          invoiceData?.invoice_discount_voucher !== undefined &&
          invoiceData?.invoice_discount_voucher !== null && (
            <div className='flex justify-between'>
              <p>Discount Voucher :</p>
              <p>- Rp. {onRupiah(invoiceData?.invoice_discount_voucher)}</p>
            </div>
          )
        }
        {
          invoiceData?.invoice_discount_company !== undefined &&
          invoiceData?.invoice_discount_company !== null && (
            <div className='flex justify-between'>
              <p>Discount Perusahaan :</p>
              <p>- Rp. {onRupiah(invoiceData?.invoice_discount_company)}</p>
            </div>
          )
        }
        {
          invoiceData?.invoice_admin_fee !== 0 && (
            <div className='flex justify-between'>
              <p>Biaya Admin :</p>
              <p>Rp. {onRupiah(invoiceData?.invoice_admin_fee)}</p>
            </div>
          )
        }
        <div className='flex justify-between'>
          <p>Pajak :</p>
          <p>Rp. {onRupiah(invoiceData?.invoice_tax)}</p>
        </div>
        {
          invoiceData?.invoice_range_price !== 0 &&
          (<div className='flex justify-between'>
            <p>Biaya Jarak :</p>
            <p>Rp. {onRupiah(invoiceData?.invoice_range_price)}</p>
          </div>)
        }
        <div className='flex justify-between text-primary-500'>
          <p>Total</p>
          <p>Rp. {onRupiah(invoiceData?.invoice_total)}</p>
        </div>
      </div>
      <div className='text-sm-regular text-gray-700'>
        <p className='mb-5'>Thank you for your business</p>
        <p className='text-sm-medium mb-2'>{invoiceData?.invoice_workshop_name}</p>
        <p className='text-gray-500'>{invoiceData?.invoice_workshop_address}</p>
      </div>
      <Divider className="m-0" />
      <div className="flex w-full gap-5 items-center justify-around">
        {/*
              <TwitterIcon />
              <LinkedinIcon />
             */}
        <FacebookIcon className={'fill-gray-400'} />
        <InstagramIcon className={'fill-gray-400'} />
      </div>
    </div>
  )
})
InvoiceContent.displayName = 'Invoice Content'
export default InvoiceContent