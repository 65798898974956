import API from "services";

const getReportList = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/reports`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    params: args.params
  });
  return response;
}
const getReportUnits = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/report/total-unit/detail`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    params: args.params
  });
  return response;
}
const getTotalExpenses = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/reports/total-expenses`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    // params: args.params
  });
  return response.data;
}
const getTotalBills = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/b2b/reports/total-bills`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    // params: args.params
  });
  return response.data;
}
const getTotalCars = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/report/total-unit`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    // params: args.params
  });
  return response.data;
}

const getUsersReport = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    params: args.params
  });
  return response.data;
}

export {
  getReportList,
  getReportUnits,
  getTotalExpenses,
  getTotalBills,
  getUsersReport,
  getTotalCars
}