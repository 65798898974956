import { Divider } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'

function CardDetailPaymentService({ dataSpareparts, dataLayanans, dataSubTotal, dataPayment, total }) {
  return (
    <CardSectionHeader headerTitle="Rincian Pembayaran" className='relative'>
      <div className='overflow-x-scroll md:overflow-auto'>
        <table className='border-spacing-2 w-full whitespace-nowrap border-separate md:whitespace-normal'>
          <thead className="text-left text-md-medium text-gray-900">
            <th className="w-[25%]">Item</th>
            <th className="w-[5%] text-right">QTY</th>
            <th>Harga</th>
            <th>Disc</th>
            <th className="text-right">Total</th>
          </thead>

          {/* sparepart list */}
          {
            dataSpareparts?.length > 0 && (
              <>
                <p className="text-md-medium text-gray-900 mt-5">Sparepart</p>
                {
                  dataSpareparts?.map((item, index) => (
                    <tr className="text-left text-md-regular text-gray-700 mb-5" key={index}>
                      <td className="w-[30%] align-top pb-2">{item.label}</td>
                      <td className="w-[5%] align-top">{item.quantity !== 0 ? `x${item.quantity}` : '-'}</td>
                      <td className="align-top">Rp {onRupiah(item.price)}</td>
                      <td className='pb-2 align-top'>
                        {
                          item?.discounts?.length > 0 ? item.discounts.map((el, i) => (
                            <p key={i}>
                              {
                                el.price_total > 0 ?
                                  `- Rp. ${onRupiah(el.price_total)} ${el.discount_type === 'PERCENT' ? `(${el?.discount}%)` : ''}` :
                                  '-'
                              }
                            </p>
                          )) : '-'
                        }
                      </td>
                      <td className="text-right align-top">Rp {onRupiah(item.total)}</td>
                    </tr>
                  ))
                }
              </>
            )
          }

          {/* layanan list */}
          {
            dataLayanans?.length > 0 && (
              <>
                <p className="text-md-medium text-gray-900 mt-5">Layanan</p>
                {
                  dataLayanans?.map((item, index) => (
                    <tr className="text-left text-md-regular text-gray-700 mb-5" key={index}>
                      <td className="w-[30%] align-top pb-2">{item.label}</td>
                      <td className="w-[5%] align-top">{item.quantity !== 0 ? `x${item.quantity}` : '-'}</td>
                      <td className=" align-top">Rp {onRupiah(item.price)}</td>
                      <td className='pb-2 align-top'>
                        {
                          item?.discounts?.length > 0 ? item.discounts.map((el, i) => (
                            <p key={i}>
                              {
                                el.price_total > 0 ?
                                  `- Rp. ${onRupiah(el.price_total)} ${el.discount_type === 'PERCENT' ? `(${el?.discount}%)` : ''}` :
                                  '-'
                              }
                            </p>
                          )) : '-'
                        }
                      </td>
                      <td className="text-right align-top">Rp {onRupiah(item.total)}</td>
                    </tr>
                  ))
                }
              </>
            )
          }

        </table>
      </div>
      <div className='flex mt-5 justify-between text-md-regular text-gray-700 w-100'>
        <p>Subtotal</p>
        <p>Rp. {onRupiah(dataSubTotal)}</p>
      </div>
      <Divider className="m-0 my-5" />
      {
        dataPayment?.map((item, index) => (
          <div className='flex justify-between text-md-regular text-gray-700 w-100' key={index}>
            <p>{item.label}</p>
            <p>Rp. {item.value}</p>
          </div>
        ))
      }
      <Divider className="m-0 my-5" />
      <div className="flex justify-between">
        <p className="text-gray-900 text-md-regular">Total Pembayaran</p>
        <p className="text-warning-500 text-md-semibold text-left">Rp. {total}</p>
      </div>
    </CardSectionHeader>
  )
}

export default CardDetailPaymentService
