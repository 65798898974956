import React from 'react'
import {
  CardDetailPayment,
  CardDetailPaymentService,
  CardDetailService,
  CardRecommendationService
} from '..'
import MenungguApprovalCard from '../templates-status-card/menunggu-approval-card'
import SelesaiServis from '../card-status-transaction/servis/selesai-servis'
import ProgressServiceCard from '../templates-status-card/progress-service-card'
import CardDetailCar from 'components/ui/card/card-detail-car'
import ServiceInfoCard from '../templates-status-card/service-info-card'
import MenungguPembayaranServis from '../card-status-transaction/servis/menunggu-pembayaran-servis'
import { onRupiah } from 'utils/formatCurrency'
import PenjadwalanServis from '../card-status-transaction/servis/penjadwalan-servis'

const ServisContentTransaction = ({
  data,
  accountType,
  userData,
  status,
  serviceName,
  navigateHandler,
  putApprovalService,
  recommendationServices,
  putRecommendationService,
  priceTotalRecommendation,
  grandTotalRecommendation,
  handleApprovalRecommendationService,
  handleApprovalService,
  isLoading,
  validationCheckPaymentMethod
}) => {
  const CardStatusTransactionServisMap = {
    waiting_payment: (
      <MenungguPembayaranServis
        isLoading={isLoading}
        data={data}
        validationCheckPaymentMethod={validationCheckPaymentMethod}
        serviceName={serviceName}
        isPersonal={
          data?.company_id == 0 && data?.user_id == userData?.id && accountType == 'customer'
        }
        isCompanyAdmin={data?.company_id == userData?.company_id && accountType == 'company'}
      />
    ),
    scheduled: (
      <PenjadwalanServis
        serviceName={serviceName}
        data={{ date: data?.service_date, start_time: data?.service_start_time }}
      />
    ),
    done_scheduled: (
      <PenjadwalanServis
        data={{ date: data?.service_date, start_time: data?.service_start_time }}
        confirmed={true}
        serviceName={serviceName}
      />
    ),
    ongoing: (
      <ProgressServiceCard
        data={{
          serviceStatusList: data?.booking_service_statuses,
          progress_finished_total: data?.progress_finished_total,
          progress_total: data?.progress_total,
          service_date: data?.service_date,
          service_end_time: data?.service_end_time
        }}
        serviceName={serviceName}
      />
    ),
    done: (
      <SelesaiServis
        serviceName={serviceName}
        data={{ date: data?.completion_time || data?.updated_at }}
      />
    ),
    waiting_approval: (
      <MenungguApprovalCard
        isApprovalServiceCompany={
          data?.company_id == userData?.company_id && accountType == 'company'
        }
        data={data}
        accountType={accountType}
        serviceName={serviceName}
        handleApprovalService={handleApprovalService}
        putApprovalService={putApprovalService}
      />
    ),
    pickup_scheduled: (
      <PenjadwalanServis
        data={{ date: data?.service_date, start_time: data?.service_start_time }}
        confirmed={true}
        serviceName={'pick up'}
      />
    ),
    pickup_done_scheduled: (
      <PenjadwalanServis
        data={{ date: data?.service_date, start_time: data?.service_start_time }}
        confirmed={true}
        serviceName={'pick up'}
      />
    ),
    pickup_ongoing: <ServiceInfoCard status={'pickup_ongoing'} />,
    cancel: <ServiceInfoCard status={'service_cancel'} />,
    rejected: <ServiceInfoCard status={'service_rejected'} />
  }
  return (
    <>
      {CardStatusTransactionServisMap[status]}
      <CardDetailCar data={data} navigateDetailHandler={navigateHandler} />
      <CardDetailService data={data} />
      {/* KHUSUS RECOMMENDATION SERVICE ADMIN PERUSAHAAN */}
      {data?.recommendation_services?.length > 0 &&
        status === 'ongoing' &&
        data?.company_id == userData?.company_id &&
        accountType == 'company' && (
          <CardRecommendationService
            putRecommendationService={putRecommendationService}
            handleApprovalRecommendationService={handleApprovalRecommendationService}
            total={priceTotalRecommendation || 0}
            grandTotal={grandTotalRecommendation}
            serviceType={data?.serviceType}
            data={recommendationServices}
          />
        )}
      {/* KHUSUS RECOMMENDATION SERVICE CUSTOMER */}
      {data?.recommendation_services?.length > 0 &&
        status === 'ongoing' &&
        data?.company_id == 0 &&
        data?.user_id == userData?.id && (
          <CardRecommendationService
            putRecommendationService={putRecommendationService}
            handleApprovalRecommendationService={handleApprovalRecommendationService}
            total={priceTotalRecommendation || 0}
            grandTotal={grandTotalRecommendation || 0}
            serviceType={data?.serviceType}
            data={recommendationServices}
          />
        )}
      <CardDetailPaymentService
        dataSpareparts={data?.dataSpareparts}
        dataLayanans={data?.dataLayanans}
        dataSubTotal={data?.dataSubTotal}
        dataPayment={data?.dataPayment}
        total={onRupiah(data?.grandTotalPayment)}
      />
      {/* <CardDetailPayment
        data={data?.dataPayment}
        total={onRupiah(data?.booking_invoice?.price_total)}
      /> */}
    </>
  )
}

export default ServisContentTransaction
